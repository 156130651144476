import styled from 'styled-components';
export const ContentContainer = styled.div `
  position: relative;
  width: 100%;
  @media (min-width: 883px) {
    margin-top: 0;
  }
`;
export const PageContainer = styled.div `
  position: relative;
`;
