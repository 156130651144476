import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const FooterContainer = styled.footer `
  width: 100%;
  background-color: #000;
  color: #fff;
  background-size: 45%;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('/assets/image/footer_bg.webp');
  @media (max-width: 1100px) {
    background-size: cover;
    background-position: center;
  }
`;
export const FooterInnerContainer = styled.div `
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 45px 54px 45px 36px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    padding-right: 36px;
  }
  ${(props) => props.theme.mixins.mobile('padding: 30px 15px;')}
`;
export const FooterRow = styled.div `
  width: 100%;
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
export const FooterMiddleRow = styled.div `
  width: 100%;
  display: flex;
  padding: 35px 0;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 34px 0 15px 0;
  }
`;
export const FooterMainInformation = styled.div `
  padding-right: 140px;
  width: 34.7%;
  @media (max-width: 1300px) {
    padding-right: 6vw;
  }
  @media (max-width: 1120px) {
    padding-right: 3vw;
  }
  @media (max-width: 1100px) {
    padding-right: 0;
    width: 100%;
    max-width: 500px;
  }
`;
export const FooterTextContainer = styled.h3 `
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;
export const FooterColumnsWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 65.3%;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 35px;
    width: 100%;
  }
`;
export const FooterColumn = styled.section `
  padding-left: ${(props) => props.paddingLeft || '0px'};
  @media (max-width: 1100px) {
    padding-bottom: 25px;
  }
`;
export const ItemHeading = styled(Link) `
  display: block;
  font-size: 1.14rem;
  line-height: 1.85rem;
  font-weight: bold;
  padding-bottom: 25px;
  @media (max-width: 1100px) {
    padding-bottom: 20px;
  }
`;
export const ItemNameLink = styled(Link) `
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
`;
export const ItemNameLinkA = styled.a `
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
`;
export const ItemNameAnchor = styled.a `
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
`;
export const FooterKMKInfo = styled.p `
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  a {
    text-decoration: underline;
  }
`;
export const FooterPaymentLogos = styled.div `
  background: #fff;
`;
export const FooterPaymentLogosContainer = styled.div `
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;
export const PaymentLogosDesktop = styled.img `
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1111px;
`;
export const PaymentLogosMobile = styled.img `
  display: block;
  margin: 0 auto;
  max-width: 287px;
  width: 100%;
  padding: 0 0 100px;
`;
